import React from "react";

const Searchbar = (props) => {
  return (
    <div className="searchbar">
      <input
        className="searchbar-input"
        type="text"
        value={props.data}
        placeholder="City Name"
        onChange={props.handleChange}
        onKeyUp={props.onSubmit}
      />
      <div onClick={props.onClick}>
        <props.icon className="searchbar-icon" />
      </div>
    </div>
  );
};
export default Searchbar;
