import React from "react";

const WeatherTimelineEntry = (props) => {
  const getHourByMilli = (millis) => {
    if (millis) {
      let day = new Date(millis * 1000);

      return day.getHours();
    }
  };

  return (
    <div
      className="weatherTimelineEntry"
      onMouseEnter={() => props.HoverEvent(props.WeatherData)}
    >
      <div className="weatherTimelineData">
        <p>
          {props.WeatherData
            ? getHourByMilli(props.WeatherData.dt) + ":00"
            : null}
        </p>
        <h2>
          {props.WeatherData ? props.WeatherData.temp.toFixed(1) + "°C" : null}
        </h2>
      </div>
      <div className="splitLine"></div>
    </div>
  );
};

export default WeatherTimelineEntry;
