import React from "react";

const WeatherForecastDay = (props) => {
  const getDateByMilli = (millis) => {
    if (millis) {
      let day = new Date(millis * 1000);
      switch (day.getDay()) {
        case 0:
          return "Sun";
        case 1:
          return "Mon";
        case 2:
          return "Tue";
        case 3:
          return "Wed";
        case 4:
          return "Thu";
        case 5:
          return "Fri";
        case 6:
          return "Sat";
      }
    } else {
      return "NoDate";
    }
  };
  let url =
    "http://openweathermap.org/img/wn/" +
    props.weatherData.weather[0].icon +
    "@2x.png";
  return (
    <div className="weatherForecastDay">
      <h4>{getDateByMilli(props.weatherData ? props.weatherData.dt : null)}</h4>
      <props.icon className="icons" />
      {/*<img src={url} alt="" />*/}
      <p>
        {props.weatherData
          ? props.weatherData.temp.min.toFixed(1) +
            " / " +
            props.weatherData.temp.max.toFixed(1)
          : "Na."}
      </p>
    </div>
  );
};

export default WeatherForecastDay;
