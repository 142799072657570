import React, { useState } from "react";
import Searchbar from "./components/Searchbar";
import WeatherForecast from "./components/WeatherForecast";

import {
  FaLocationArrow,
  FaSun,
  FaCloudSun,
  FaCloudSunRain,
  FaCloudRain,
  FaSnowflake,
  FaBolt,
  FaSmog,
  FaCloud,
  FaHourglass,
} from "react-icons/fa";

const apiKey = "acf73c0a066222c33fd773a6f50063af";

const App = () => {
  const [data, setData] = useState("");
  const [weather, setWeather] = useState({});
  const [location, setLocation] = useState({});

  const handleInputChange = (e) => {
    setData(e.target.value);
  };

  const handleClick = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      fetchWeatherCurrentPos(
        position.coords.latitude,
        position.coords.longitude
      );
    });
  };

  const handleSubmit = (e) => {
    if (e.keyCode === 13) {
      console.log(data + " has been submitted!");
      fetchWeather();
      setData("");
    }
  };

  const fetchWeather = async () => {
    let response = await fetch(
      `https://api.openweathermap.org/geo/1.0/direct?q=${data}&appid=${apiKey}`
    );
    let coords = await response.json();
    setLocation(coords);

    setWeather({});
    response = await fetch(
      `https://api.openweathermap.org/data/2.5/onecall?lat=${
        coords[0] ? coords[0].lat : null
      }&lon=${
        coords[0] ? coords[0].lon : null
      }&exclude=minutely,alerts&appid=${apiKey}&units=metric`
    );

    setWeather(await response.json());
  };

  const fetchWeatherCurrentPos = async (lat, lon) => {
    setLocation([
      { name: "Current Position", state: lat + "°N,  " + lon + "°E" },
    ]);
    setWeather({});
    let response = await fetch(
      `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=minutely,alerts&appid=${apiKey}&units=metric`
    );

    setWeather(await response.json());
  };

  return (
    <div className={weather.current ? "app" : "app centered"}>
      <Searchbar
        handleChange={handleInputChange}
        data={data}
        onClick={handleClick}
        onSubmit={handleSubmit}
        icon={FaLocationArrow}
      />

      {weather.current ? (
        <WeatherForecast
          CityName={location && location[0] ? location[0].name : null}
          state={location && location[0] ? location[0].state : null}
          WeatherData={weather}
          Sun={FaSun}
          CloudSun={FaCloudSun}
          CloudSunRain={FaCloudSunRain}
          Rain={FaCloudRain}
          Snow={FaSnowflake}
          Storm={FaBolt}
          Fog={FaSmog}
          Cloud={FaCloud}
          None={FaHourglass}
        />
      ) : (
        <p className="message">Please select a City</p>
      )}
    </div>
  );
};

export default App;
