import React from "react";

const WeatherDetails = (props) => {
  return (
    <div className="weatherDetail">
      <div className="detailItem">
        <h3>Temperature</h3>
        <p>{props.weather ? props.weather.temp.toFixed(1) + "°C" : null}</p>
      </div>
      <div className="detailItem">
        <h3>Feels like</h3>
        <p>
          {props.weather ? props.weather.feels_like.toFixed(1) + "°C" : null}
        </p>
      </div>
      <div className="detailItem">
        <h3>Humidity</h3>
        <p>{props.weather ? props.weather.humidity + "%" : null}</p>
      </div>
      <div className="detailItem">
        <h3>UV Index</h3>
        <p>{props.weather ? props.weather.uvi + " UV" : null}</p>
      </div>
      <div className="detailItem">
        <h3>Wind speed</h3>
        <p>
          {props.weather
            ? (props.weather.wind_speed * 3.6).toFixed(1) + " km/h"
            : null}
        </p>
      </div>
      <div className="detailItem">
        <h3>Weather</h3>
        <p>{props.weather ? props.weather.weather[0].description : null}</p>
      </div>
    </div>
  );
};

export default WeatherDetails;
