import React, { useState } from "react";
import WeatherTimelineEntry from "./WeatherTimelineEntry.js";
import WeatherDetails from "./WeatherDetails.js";

const WeatherTimeline = (props) => {
  const [Data, setData] = useState("");
  const CreateEntries = () => {
    let output = [];
    for (let i = 0; i < (props.Weather ? 24 : 0); i++) {
      output.push(
        <WeatherTimelineEntry
          HoverEvent={setData}
          WeatherData={props.Weather[i]}
        />
      );
    }
    return output;
  };

  return (
    <div className="timelineContainer">
      <div className="weatherTimeline">
        <CreateEntries />
      </div>
      {Data.temp ? <WeatherDetails weather={Data} /> : null}
    </div>
  );
};

export default WeatherTimeline;
