import React from "react";
import WeatherForecastDay from "./WeatherForecastDay";
import WeatherTimeline from "./WeatherTimeline";

const WeatherForecast = (props) => {
  const chooseIcon = (weather) => {
    switch (weather.weather[0].main) {
      case "Clear":
        return props.Sun;
      case "Clouds":
        return props.Cloud;
      case "Atmosphere":
        return props.Fog;
      case "Snow":
        return props.Snow;
      case "Rain":
        return props.Rain;
      case "Drizzle":
        return props.CloudSunRain;
      case "Thunderstorm":
        return props.Storm;
      default:
        return props.None;
    }
  };

  const CreateWeatherForecastDay = () => {
    let output = [];
    for (let i = 0; i < 5; i++) {
      output.push(
        <WeatherForecastDay
          weatherData={
            props.WeatherData.daily ? props.WeatherData.daily[i] : null
          }
          icon={
            props.WeatherData.daily
              ? chooseIcon(props.WeatherData.daily[i])
              : null
          }
        />
      );
    }
    return output;
  };

  return (
    <div>
      <header className="weatherHeader">
        <div className="location">
          <h1>{props.CityName}</h1>
          <h2>{props.state}</h2>
        </div>
        <h1>
          {props.WeatherData.current
            ? props.WeatherData.current.temp.toFixed(1) + " °C"
            : null}
        </h1>
      </header>

      <WeatherTimeline
        Weather={props.WeatherData.hourly ? props.WeatherData.hourly : null}
      />

      <div className="weatherForecast">
        <CreateWeatherForecastDay />
      </div>
    </div>
  );
};

export default WeatherForecast;
